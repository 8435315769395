import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import notifier from "simple-react-notifications";
import Spinner from "./Spinner";
import { Redirect } from "react-router-dom";
import { LoadingContext } from "../App";

function Registracija(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initFormData = {
    email: "",
    ime: "",
    prezime: "",
    adresa: "",
    mjesto: "",
    mob: "",
    username: "",
    zaporka: ""
  };
  const [formData, setFormData] = useState(initFormData);
  const handleChange = event => {
    const target = event.target;
    setFormData({ ...formData, [target.name]: target.value });
  };

  const { setLoading } = useContext(LoadingContext);
  const [redirect, setRedirect] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_API_URL}/users/`, formData)
      .then(function(response) {
        if (response.data.ok) {
          notifier.success(
            "Poruka s linkom za aktivaciju računa je poslana na vašu e-adresu."
          );
          setRedirect(true);
        } else {
          setLoading(false);
          notifier.error(response.data.msg);
        }
      })
      .catch(function(error) {
        console.log(error);
        const email = process.env.REACT_APP_EMAIL;
        setLoading(false);
        notifier.error(
          `Dogodila se greška prilikom registriranja. Probajte ponovno za par minuta. U slučaju da i dalje imate problema, javite nam na e-adresu ${email}.`
        );
      });
  };

  if (redirect) {
    setLoading(false);
    return <Redirect to="/" />;
  }

  return (
    <main id="main-content">
      <h2 className="text-center">Registracija</h2>
      <article className="cijene-emailom-obrazac">
        <form className="registracija-mailom" onSubmit={handleSubmit}>
          <div className="form-items">
            <div className="form-item">
              <input
                type="text"
                id="ime"
                name="ime"
                placeholder="Ime"
                size="30"
                required
                value={formData.ime}
                onChange={handleChange}
              />
              {/* <label htmlFor="ime">Ime</label> */}
            </div>
          </div>
          <div className="form-items">
            <div className="form-item">
              <input
                type="text"
                id="prezime"
                name="prezime"
                placeholder="Prezime"
                size="30"
                required
                value={formData.prezime}
                onChange={handleChange}
              />
              {/* <label htmlFor="prezime">Prezime</label> */}
            </div>
          </div>
          <div className="form-items">
            <div className="form-item">
              <input
                type="text"
                id="adresa"
                name="adresa"
                placeholder="adresa"
                // pattern=".+@globex.com"

                required
                value={formData.adresa}
                onChange={handleChange}
              />
              {/* <label htmlFor="adresa">Adresa</label> */}
            </div>
          </div>
          <div className="form-items">
            <div className="form-item">
              <input
                type="text"
                id="mjesto"
                name="mjesto"
                placeholder="Mjesto"
                // pattern=".+@globex.com"

                required
                value={formData.mjesto}
                onChange={handleChange}
              />
              {/* <label htmlFor="mjeto">Mjesto</label> */}
            </div>
          </div>
          <div className="form-items">
            <div className="form-item">
              <input
                type="text"
                id="mob"
                name="mob"
                placeholder="Mobitel/telefon"
                value={formData.mob}
                onChange={handleChange}
              />
              {/* <label htmlFor="mob">Mobitel/telefon</label> */}
            </div>
          </div>
          <div className="form-items">
            <div className="form-item">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="E-adresa"
                // pattern=".+@globex.com"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                size="30"
                required
                value={formData.email}
                onChange={handleChange}
              />
              {/* <label htmlFor="email">E-adresa</label> */}
            </div>
          </div>
          <div className="form-items">
            <div className="form-item">
              <input
                type="text"
                id="username"
                name="username"
                placeholder="Korisničko ime"
                // pattern=".+@globex.com"

                required
                value={formData.username}
                onChange={handleChange}
              />
              {/* <label htmlFor="email">Korisničko ime</label> */}
            </div>
          </div>
          <div className="form-items">
            <div className="form-item">
              <input
                type="password"
                id="zaporka"
                name="zaporka"
                placeholder="Lozinka"
                // pattern=".+@globex.com"

                required
                value={formData.zaporka}
                onChange={handleChange}
              />
              {/* <label htmlFor="email">Lozinka</label> */}
            </div>
          </div>
          <div className="form-submit">
            <input type="submit" value="Registracija" />
          </div>
        </form>
      </article>
    </main>
  );
}

export default Registracija;

import React, { useState, useEffect } from "react";
import Postaja from "./Postaja";
import ErrorComponent from "./ErrorComponent";

function ObvezniciRezultati(props) {
  const { postaje, atLeastOneElected } = props;

  const [brojPrikazanihPostaja, setBrojPrikazanihPostaja] = useState();
  useEffect(() => {
    setBrojPrikazanihPostaja(Math.min(5, postaje.length));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postaje.length]);

  const handleClick = e => {
    const noviBrojPrikazanihPostaja = Math.min(
      brojPrikazanihPostaja + 10,
      postaje.length
    );
    setBrojPrikazanihPostaja(noviBrojPrikazanihPostaja);
  };

  if (atLeastOneElected && postaje.length === 0) {
    return (
      <section className="obveznici-rezultati">
        <ErrorComponent
          subject={
            "Na ovom području nema postaja. Pogledajte imate li upaljene filtere. Pokušajte povećati doseg ili potražiti drugu lokaciju."
          }
          subjectStyle={{ fontSize: "14px", marginBottom: "40px" }}
          titleStyle={{ marginTop: "20px", display: "block" }}
        />
      </section>
    );
  }

  return (
    <section className="obveznici-rezultati">
      {postaje.slice(0, 5).map(postaja => (
        <Postaja key={postaja.id} postaja={postaja} />
      ))}
      <hr />
      {postaje.slice(5, brojPrikazanihPostaja).map(postaja => (
        <Postaja key={postaja.id} postaja={postaja} />
      ))}
      {brojPrikazanihPostaja === postaje.length ? null : (
        <button className="obveznici-jos" type="button" onClick={handleClick}>
          Učitaj još
        </button>
      )}
    </section>
  );
}

export default ObvezniciRezultati;

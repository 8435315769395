export function getCurrentDay() {
  const currentDate = new Date();

  let currentDay;
  switch (currentDate.getDay()) {
    case 0:
      currentDay = "nedjelja";
      break;
    case 6:
      currentDay = "subota";
      break;
    default:
      currentDay = "radni";
      break;
  }

  return currentDay;
}

export function getCurrentTime() {
  const currentDate = new Date();

  const currentTime =
    currentDate
      .getHours()
      .toString()
      .padStart(2, "0") +
    ":" +
    currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0") +
    ":" +
    currentDate
      .getSeconds()
      .toString()
      .padStart(2, "0");

  return currentTime;
}

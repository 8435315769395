import React from "react";
import { Link } from "react-router-dom";

import GorivaPostaje from "../widgets/GorivaPostaje";

import { getCurrentDay, getCurrentTime } from "./tmp/util";
import { createAddOpenConsumer } from "./tmp/postajaConsumers";

function PostajaNaKarti(props) {
  const { postaja, showDetails } = props;

  const currentDay = getCurrentDay();
  const currentTime = getCurrentTime();
  createAddOpenConsumer(currentDay, currentTime)(postaja);

  let dataPostajaRv, postajaRvDt;
  dataPostajaRv = postajaRvDt = "Zatvoreno";
  if (postaja.open) {
    dataPostajaRv = postajaRvDt = "Otvoreno";
  }

  return (
    <article className="postaja-na-karti">
      <div className="postaja-link-card">
        <Link to={postaja.url}>
          <h3>{postaja.naziv}</h3>
          {postaja.obveznik.logo ? (
            <img
              className="logo-obveznika"
              src={
                process.env.REACT_APP_LOGO_URL_PREFIX + postaja.obveznik.logo
              }
              alt={postaja.obveznik.naziv}
            ></img>
          ) : (
              <h6 className="mala-margina">{postaja.obveznik.naziv}</h6>
            )}
        </Link>
        {/* <dl className="postaja-rv-card" data-postaja-rv={dataPostajaRv}>
          <dt>{postajaRvDt}</dt>
          {postaja.radnoVrijemesDanas &&
            postaja.radnoVrijemesDanas.map(rv => (
              <dd key={rv.id}>
                <time key={1}>{rv.pocetak.slice(0, 5)}</time>–
                <time key={2}>{rv.kraj.slice(0, 5)}</time>
              </dd>
            ))}
        </dl> */}
        <dl className="postaja-rv-card" data-postaja-rv={dataPostajaRv}>
          <dd>Radno vrijeme:</dd>
          {postaja.radnoVrijemesDanas &&
            postaja.radnoVrijemesDanas.map(rv => (
              <dd key={rv.id}>
                <time key={1}>{rv.pocetak.slice(0, 5)}</time>–
                <time key={2}>{rv.kraj.slice(0, 5)}</time>
              </dd>
            ))}
          <dt>{postajaRvDt}</dt>
        </dl>
        <address className="postaja-lokacija-card">
          <ul>
            <li>{postaja.adresa + ", " + postaja.mjesto}</li>
            <li className="postaja-proximity">
              {postaja.udaljenost !== undefined
                ? postaja.udaljenost + " km"
                : null}
              {/* <small>
                <time dateTime="PT14M">(∞ min)</time>
              </small> */}
            </li>
          </ul>
        </address>
        <GorivaPostaje postaja={postaja} />
        {/* <ul className="postaja-cijene">
          {postaja.cijeneGoriva.map(cijenaGoriva => (
            <li
              key={cijenaGoriva.id}
              data-postaja-gorivo={
                tipGorivaToDataPostajaGorivo[
                  cijenaGoriva.gorivo.vrsta_goriva.tip_goriva_id
                ]
              }
              data-postaja-unit="kn/L"
            >
              {cijenaGoriva.cijena}
              <span className="postaja-cijena-unit">kn/L</span>
            </li>
          ))}
        </ul> */}
        {/* {showDetails && <div aria-hidden="true">Detalji</div>} */}
      </div>
    </article>
  );
}

export default PostajaNaKarti;

import notifier from "simple-react-notifications";

export function error(postitionError) {
  if (postitionError.code === 1)
    notifier.error(
      "Dozvola za geolokaciju blokirana je jer je korisnik nekoliko puta odbacio upit za dozvolu. To se može resetirati u informacijama o stranici kojima se može pristupiti klikom na ikonu zaključavanja pored URL-a."
    );
  else {
    notifier.error(postitionError.message);
  }
}

import React, { useEffect } from "react";

function Projekt(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main-content">
      <article className="projekt-opis">
        <div className="projekt-tekst">
          <h2>O projektu</h2>
          <p>
            Web portal i aplikacija za mobilne uređaje s informacijama o
            maloprodajnim cijenama naftnih derivata u Republici Hrvatskoj
          </p>
          <p>
            Na web portalu su prikazane cijene svih naftnih derivata na svim
            benzinskim postajama i punionicama autoplina u Republici Hrvatskoj.
            Aplikacija pokazuje korisnicima gdje se nalaze benzinske postaje sa
            najjeftinijim gorivima, ovisno o lokaciji korisnika.
          </p>
          <p>
            Osnovni ciljevi za pokretanje Web portala i aplikacije o cijenama
            goriva bili su dodatna zaštita potrošača, poticanje tržišnog
            natjecanja među trgovcima naftnim derivatima i konkurentnije
            određivanje cijena naftnih derivata, pojačavanje tržišne utakmice,
            određivanje cijena na dnevnoj bazi.
          </p>
          <p>
            Stvoreni su uvjeti za trenutačni i potpuni uvid u promjene cijena
            svih trgovaca naftnim derivatima.
          </p>
        </div>
        <img className="projekt-slika" src="/min2goTema/img/01-o-projektu.jpg" alt="" />
        <section className="projekt-brojke">
          <h2>Projekt u brojkama</h2>
          <dl>
            <dt>Obveznika</dt>
            <dd>130+</dd>
            <dt>Postaja</dt>
            <dd>1000+</dd>
            <dt>Unešenih cijena</dt>
            <dd>2 000 000+</dd>
          </dl>
        </section>
        <div className="projekt-tekst">
          <h3>Privatnost podataka</h3>
          <p>
            Vaša nam je privatnost iznimno važna. Pravila privatnosti korisnika
            ove Internet stranice opisuju kako i u koje svrhe Ministarstvo gospodarstva i održivog razvoja prikuplja te kako koristi vaše osobne
            podatke. Molimo Vas da se upoznate s praksom zaštite privatnosti.
          </p>
          <h3>Prikupljanje i korištenje osobnih podataka</h3>
          <p>
            Ministarstvo gospodarstva i održivog razvoja poštuje privatnost
            korisnika Internet stranice te prikupljene podatke neće prenositi
            trećoj strani. Podaci će se koristiti samo za rješavanje
            pojedinačnih zahtjeva korisnika za dodatnim uslugama te za prijave,
            upite i komentare.
          </p>
          <h3>Kako koristimo osobne podatke koje prikupljamo</h3>
          <p>
            Osobne podatke koristimo kako bi vam: pružili usluge koje zatražite,
            udovoljili pojedinačnim zahtjevima za određenim uslugama, omogućili
            prijavu nadležnim inspekcijskim službama, odgovorili na vaše
            komentare ili upite, unaprijedili Internet stranicu i/ili
            dijagnosticirali eventualne probleme pri korištenju Internet
            stranice. Vaše ćemo osobne podatke pohraniti samo na razdoblje
            potrebno u gore navedene svrhe te ih nakon toga izbrisati.
          </p>
        </div>
        <img className="projekt-slika" src="/min2goTema/img/03-o-projektu-pravila-privatnosti.jpg" alt="" />
        <div className="projekt-tekst">
          <h2>Upute za korištenje</h2>
          <p>
            Ukoliko pretražujete mjesto koje ima identičan naziv s nekim drugim
            mjestom (npr Ivanec), u adresu napišite i županiju u ovom formatu:
            Ivanec, Varaždinska županija Postaje možete pretraživati i prema
            njihovim dodatnim uslugama. Kliknite Detaljno filtriranje ispod mape
            i pronađite postaje s dodatnim karakteristikama, ponudama i uslugama
          </p>
          <ul className="makni-padding">
            <li>
              {" "}
              Ukoliko želite primati obavijesti o cijenama goriva na email,
              prijavite se pomoću forme na dnu naslovne stranice
            </li>
            <li>
              {" "}
              Stranicu je preporučljivo pregledavati pomoću preglednika Chrome,
              Safari, Firefox ili novije verzije Internet Explorer (10 i 11){" "}
            </li>
            <li>
              {" "}
              Na stranici svake postaje možete vidjeti trend kretanja cijena
              osnovnih vrsta goriva
            </li>
          </ul>
        </div>
        <img className="projekt-slika" src="/min2goTema/img/02-o-projektu-upute-za-koristenje.jpg" alt="" />
      </article>
    </main>
  );
}

export default Projekt;

import React, { useEffect } from "react";

function Privanost(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main id="main-content">


      <article className="projekt-opis">
        <div className="projekt-tekst">
          <h3>Privatnost podataka</h3>
          <p>
            Vaša nam je privatnost iznimno važna. Pravila privatnosti korisnika
            ove Internet stranice opisuju kako i u koje svrhe Ministarstvo gospodarstva i održivog razvoja prikuplja te kako koristi vaše osobne
            podatke. Molimo Vas da se upoznate s praksom zaštite privatnosti.
          </p>
          <h3>Prikupljanje i korištenje osobnih podataka</h3>
          <p>
            Ministarstvo gospodarstva i održivog razvoja poštuje privatnost
            korisnika Internet stranice te prikupljene podatke neće prenositi
            trećoj strani. Podaci će se koristiti samo za rješavanje
            pojedinačnih zahtjeva korisnika za dodatnim uslugama te za prijave,
            upite i komentare.
          </p>
          <h3>Kako koristimo osobne podatke koje prikupljamo</h3>
          <p>
            Osobne podatke koristimo kako bi vam: pružili usluge koje zatražite,
            udovoljili pojedinačnim zahtjevima za određenim uslugama, omogućili
            prijavu nadležnim inspekcijskim službama, odgovorili na vaše
            komentare ili upite, unaprijedili Internet stranicu i/ili
            dijagnosticirali eventualne probleme pri korištenju Internet
            stranice. Vaše ćemo osobne podatke pohraniti samo na razdoblje
            potrebno u gore navedene svrhe te ih nakon toga izbrisati.
          </p>
        </div>
        <img className="projekt-slika" src="/min2goTema/img/03-o-projektu-pravila-privatnosti.jpg" alt="" />
      </article>

    </main>
  );
}

export default Privanost;

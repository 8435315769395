import React, { useEffect } from "react";
import Chartist from "chartist";
import "chartist-plugin-accessibility";
import "chartist-plugin-pointlabels";
import "chartist-plugin-legend";
import "chartist-plugin-tooltips";

function NaslovnicaGrafikon(props) {
  const { labels, series, legendNames } = props;

  var title = props.title;
  if (title === undefined) {
    title = "Grafikon cijena";
  }

  var data = {
    // A labels array that can contain any sort of values
    // labels: ['22 kol', '23 kol', '24 kol', '25 kol', '26 kol'],
    // Our series array that contains series objects or in this case series data arrays
    // series: [
    //   ([4.7, 4.2, 4.2, 8.1, 8.6]).map(x => ({meta: 'H2', value: x})),
    //   ([null, 4.7, 4.7, 4.7, 6.5]).map(x => ({meta: 'E5', value: x})),
    //   ([4.8, 5.2, 6.7, 5.6, 5.9]).map(x => ({meta: 'B7', value: x}))
    // ]
    labels: labels,
    series: series
  };

  // We are setting a few options for our chart and override the defaults
  var options = {
    fullWidth: false,
    chartPadding: {
      bottom: 70
    },
    // lineSmooth: Chartist.Interpolation.step(),
    // Y-Axis specific configuration
    axisY: {
      // Lets offset the chart a bit from the labels
      offset: 80,
      // The label interpolation function enables you to modify the values
      // used for the labels on each axis. Here we are converting the
      // values into million dollars: '$' + return value + 'm';
      // labelInterpolationFnc: function(value) {
      //   return value.toFixed(2) + ' kn/L';
      // },
      // This option will be used when finding the right scale division settings. The amount of ticks on the scale will be determined so that as many ticks as possible will be displayed, while not violating this minimum required space (in pixel).
      scaleMinSpace: 50
    },
    axisX: {
      showGrid: false,
      labelInterpolationFnc: function skipLabels(value, index, array) {
        const mod = Math.ceil(array.length / 12);
        return index % mod === 0 ? value : null;
      }
    },
    plugins: [
      Chartist.plugins.ctAccessibility({
        caption: "Kretanje cijene goriva na postaji",
        seriesHeader: "Vrste goriva",
        summary:
          "Grafikon koji pokazuje promjene cijene vrsta goriva na ovoj postaji"
        // valueTransform: function(value) {
        //   return value.toFixed(2) + ' kn/L';
        // },
        // ONLY USE THIS IF YOU WANT TO MAKE YOUR ACCESSIBILITY TABLE ALSO VISIBLE!
        // visuallyHiddenStyles: 'position: absolute; top: 100%; width: 100%; font-size: 11px; overflow-x: auto; background-color: #efefef; padding: 10px;z-index:5;left: 0;'
      }),
      // Wrongly stated in the README on GitHub as ctPointLabels instead of Chartist.plugins.ctPointLabels
      // Chartist.plugins.ctPointLabels({
      //   labelInterpolationFnc: function(value) {return value.toFixed(2) + ' kn/L'},
      //   textAnchor: 'left',
      //   align: 'left',
      //   labelOffset: {
      //     x: 5,
      //     y: -5
      //   },
      // }),
      Chartist.plugins.legend({
        position: "top",
        legendNames: legendNames
      }),
      Chartist.plugins.tooltip()
    ]
  };

  var responsiveOptions = [
    // You can even use responsive configuration overrides to
    // customize your series configuration even further!
    [
      "screen and (max-width: 620px)",
      {
        // Y-Axis specific configuration
        axisY: {
          offset: 50,
          scaleMinSpace: 30
        },
        axisX: {
          showGrid: false,
          labelInterpolationFnc: function skipLabels(value, index, array) {
            const mod = Math.ceil(array.length / 5);
            return index % mod === 0 ? value : null;
          }
        },
        plugins: [
          Chartist.plugins.ctPointLabels({
            labelInterpolationFnc: ""
          })
        ]
      }
    ]
  ];
  useEffect(() => {
    // Create a new line chart object where as first parameter we pass in a selector
    // that is resolving to our chart container element. The Second parameter
    // is the actual data object.
    new Chartist.Line(
      "#postaja-cijene-grafikon",
      data,
      options,
      responsiveOptions
    );
  }, []);

  return (
    <section className="postaja-grafikon">
      <h2>{title}</h2>
      <div
        id="postaja-cijene-grafikon"
        className="chartist-grafikon ct-chart ct-golden-section"
      ></div>
    </section>
  );
}

export default NaslovnicaGrafikon;

import React from "react";

function GorivaPostaje(props) {
  const { postaja } = props;

  postaja.cijeneGoriva.sort(
    (a, b) =>
      a.gorivo.vrsta_goriva.tip_goriva.id - b.gorivo.vrsta_goriva.tip_goriva.id
  );

  const oznakaINazivGoriva = postaja.cijeneGoriva.map(cijenaGoriva => {
    const tipGoriva = cijenaGoriva.gorivo.vrsta_goriva.tip_goriva.tip_goriva;
    var tipGorivaHtml, className;
    let oznaka = cijenaGoriva.gorivo.oznaka;
    if (oznaka) {
      oznaka = oznaka.toUpperCase();
    }
    switch (tipGoriva) {
      case "Benzinska goriva":
        tipGorivaHtml = oznaka ? oznaka : "E";
        className = "postaja-zelena-oznaka";
        break;
      case "Dizelska goriva":
        tipGorivaHtml = oznaka ? oznaka : "B";
        className = "postaja-crna-oznaka";
        break;
      case "Autoplin":
        tipGorivaHtml = (
          <p className="rotacija-teksta">{oznaka ? oznaka : "H"}</p>
        );
        className = "postaja-zuta-oznaka";
        break;
      case "Plinsko ulje":
        tipGorivaHtml = "";
        className = "postaja-crvena-oznaka";
        break;
      default:
        break;
    }
    return (
      <div key={cijenaGoriva.id}>
        <div className={className}>{tipGorivaHtml}</div>
        <h6 className="cijena">{cijenaGoriva.gorivo.naziv}</h6>
      </div>
    );
  });

  const cijenaGoriva = postaja.cijeneGoriva.map(cijenaGoriva => (
    <div key={cijenaGoriva.id}>
      <span className="bold-cijena">{cijenaGoriva.cijena}</span>
      <span className="postaja-cijena-unit">kn/L</span>
    </div>
  ));

  const style = {
    display: "grid",
    gridTemplateColumns: `repeat(${postaja.cijeneGoriva.length}, 1fr)`,
    gridTemplateRows: "1fr 0.3fr",
    gridColumnGap: "5px",
    gridRowGap: "10px",
    padding: "1.4em 0 1.4em 0"
  };

  return (
    <div style={style} className="text-center">
      {oznakaINazivGoriva}
      {cijenaGoriva}
    </div>
  );
}

export default GorivaPostaje;

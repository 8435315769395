import L from "leaflet";

var openIcon = L.divIcon({
  className: "my-div-icon",
  html: "<div class='outer-circle-green '></div>"
});

var closedIcon = L.divIcon({
  className: "my-div-icon",
  html: "<div class='outer-circle-red'></div>"
});

var openLabelIcon = L.divIcon({
  className: "my-div-icon",
  html: "<div class='outer-circle-green hover-border'></div>"
});

var closedLabelIcon = L.divIcon({
  className: "my-div-icon",
  html: "<div class='outer-circle-red hover-border'></div>"
});

const getPostajaIcon = (postaja, label) =>
  label
    ? postaja.open
      ? openLabelIcon
      : closedLabelIcon
    : postaja.open
    ? openIcon
    : closedIcon;

export { getPostajaIcon };

import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";

import Postaja from "../components/Postaja";
import ErrorComponent from "../components/ErrorComponent";

function TrazilicaRezultatiOtvorene2(props) {
  const { postajas, onMouseEnter, onMouseLeave } = props;

  const openPostajas = [];
  postajas.some(postaja => {
    if (postaja.open) {
      openPostajas.push(postaja);
    }
    return !postaja.open;
  });
  const closedPostajas = postajas.slice(openPostajas.length);
  const [loadedOpenPostajas, setOpenLoadedPostajas] = useState(0);
  const loadItems = page => {
    setOpenLoadedPostajas(
      Math.min(loadedOpenPostajas + 10, openPostajas.length)
    );
  };
  const [loadedClosedPostajas, setClosedLoadedPostajas] = useState(0);
  const loadItems2 = page => {
    setClosedLoadedPostajas(
      Math.min(loadedClosedPostajas + 10, closedPostajas.length)
    );
  };

  const loader = (
    <div key={0} className="loader">
      Učitavanje ...
    </div>
  );

  const [isTimeout, setIsTimeout] = useState(true);
  useEffect(() => {
    setIsTimeout(true);

    let didCancel = false;
    setTimeout(() => {
      if (didCancel) {
        return;
      }
      setIsTimeout(false);
    }, 3000);

    return () => {
      didCancel = true;
    };
  }, [postajas.length]);

  var items = [];
  openPostajas.slice(0, loadedOpenPostajas).map((postaja, i) => {
    items.push(
      <Postaja
        key={postaja.id}
        postaja={postaja}
        showDetails={false}
        onMouseEnter={isTimeout ? null : onMouseEnter}
        onMouseLeave={isTimeout ? null : onMouseLeave}
      />
    );
  });

  var items2 = [];
  closedPostajas.slice(0, loadedClosedPostajas).map((postaja, i) => {
    items2.push(
      <Postaja
        key={postaja.id}
        postaja={postaja}
        showDetails={false}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    );
  });

  if (openPostajas.length === 0 && closedPostajas.length === 0) {
    return (
      <section
        className="trazilica-rezultati-otvorene"
        style={{ height: "auto" }}
      >
        <ErrorComponent
          subject={
            "Na ovom području nema postaja.  Pogledajte imate li upaljene filtere. Pokušajte povećati doseg ili potražiti drugu lokaciju."
          }
          subjectStyle={{ fontSize: "14px", marginBottom: "40px" }}
          titleStyle={{ marginTop: "20px" }}
        />
      </section>
    );
  }

  return (
    <section className="trazilica-rezultati-otvorene">
      {openPostajas.length !== 0 && <h2>Otvorene postaje</h2>}
      <InfiniteScroll
        pageStart={0}
        loadMore={loadItems}
        hasMore={loadedOpenPostajas < openPostajas.length}
        loader={loader}
        useWindow={false}
      >
        {items}
      </InfiniteScroll>
      {closedPostajas.length !== 0 && <h2>Zatvorene postaje</h2>}
      <InfiniteScroll
        pageStart={0}
        loadMore={loadItems2}
        hasMore={loadedClosedPostajas < closedPostajas.length}
        loader={loader}
        useWindow={false}
      >
        {items2}
      </InfiniteScroll>
    </section>
  );
}

export default TrazilicaRezultatiOtvorene2;

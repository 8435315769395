import React, { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import RequiredPopup from "./RequiredPopup";
import axios from "axios";
import notifier from "simple-react-notifications";

import "react-datepicker/dist/react-datepicker.css";

import { DataContext, AuthContext, LoadingContext } from "../App";
import Spinner from "./Spinner";

function PrijavaGreske2(props) {
  //const { userToken } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { auth, signOut } = useContext(AuthContext);

  const { setLoading } = useContext(LoadingContext);
  useEffect(() => {
    // setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/check`, {
        headers: { Authorization: `JWT ${auth.token}` }
      })
      .then(function(response) {
        // setLoading(false);
      })
      .catch(function(error) {
        // setLoading(false);
        if (error.response.status === 401) {
          notifier.info(
            "Vaše vjerodajnice su istekle. Molimo prijavite se ponovno."
          );
          signOut();
        }
      });
  }, []);

  const { obvezniks, postajas } = useContext(DataContext);

  const [selectedObveznik, setSelectedObveznik] = useState();
  const [selectedPostaja, setSelectedPostaja] = useState();
  useEffect(() => {
    setSelectedPostaja(null);
  }, [selectedObveznik]);

  const [date, setDate] = useState();
  const [razlog, setRazlog] = useState("");
  const [napomena, setNapomena] = useState("");

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);

    // const mm = date.getMonth() + 1; // getMonth() is zero-based
    // const dd = date.getDate();
    // const datum = [
    //   date.getFullYear(),
    //   (mm > 9 ? "" : "0") + mm,
    //   (dd > 9 ? "" : "0") + dd
    // ].join("-");
    const data = {
      postaja_id: selectedPostaja.value,
      //datum: datum,
      razlog: razlog,
      napomena: napomena
    };

    axios
      // .post(
      //   `${process.env.REACT_APP_BACKEND_API_URL}/prijava-greskes2/`,
      //   data,
      //   {
      //     headers: { Authorization: `JWT ${userToken}` }
      //   }
      // )
      .post(
        `${process.env.REACT_APP_BACKEND_API_URL}/prijava-greskes2/`,
        data,
        {
          headers: { Authorization: `JWT ${auth.token}` }
        }
      )
      .then(function(response) {
        setSelectedObveznik(null);
        setSelectedPostaja(null);
        setDate(null);
        setRazlog("");
        setNapomena("");

        setLoading(false);
        notifier.success("Uspješno ste prijavili grešku.");
      })
      .catch(function(error) {
        console.log(error);
        setLoading(false);
        if (error.response.status === 401) {
          notifier.info(
            "Vaše vjerodajnice su istekle. Molimo prijavite se ponovno."
          );
          signOut();
        } else {
          notifier.error(
            `Dogodila se greška prilikom prijave greške. Probajte ponovno za par minuta. U slučaju da i dalje imate problema, javite nam na e-adresu ${process.env.REACT_APP_EMAIL}.`
          );
        }
      });
  };

  return (
    <article id="main-content" className="cijene-emailom-obrazac">
      <h1>Prijava greške</h1>
      <form className="registracija-mailom" onSubmit={handleSubmit}>
        <div className="form-items">
          <div className="form-item smallselect">
            <label htmlFor="obveznik-dropdown">Obveznik</label>
            <Select
              placeholder={"Unesite ime obveznika"}
              value={selectedObveznik}
              onChange={option => setSelectedObveznik(option)}
              options={obvezniks.map(obveznik => ({
                value: obveznik.id,
                label: obveznik.naziv
              }))}
              isMulti={false}
              filterOption={(option, input) => {
                input = input.trim();
                //   if (input.length < 2) {
                //     return false;
                //   }

                return option.label
                  .toLowerCase()
                  .startsWith(input.toLowerCase());
              }}
              name="obveznik-dropdown"
            />
            {!selectedObveznik && (
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                required
              />
            )}
          </div>
          <div className="form-item smallselect">
            <label htmlFor="postaja-dropdown">Postaja</label>
            <Select
              placeholder={"Unesite ime postaje"}
              value={selectedPostaja}
              onChange={option => setSelectedPostaja(option)}
              options={postajas
                .filter(
                  postaja =>
                    selectedObveznik &&
                    postaja.obveznik_id === selectedObveznik.value
                )
                .map(postaja => ({
                  value: postaja.id,
                  label: postaja.naziv
                }))}
              isMulti={false}
              filterOption={(option, input) => {
                input = input.trim();
                //   if (input.length < 2) {
                //     return false;
                //   }

                return option.label
                  .toLowerCase()
                  .startsWith(input.toLowerCase());
              }}
              name="postaja-dropdown"
            />
            {!selectedPostaja && (
              <input
                tabIndex={-1}
                autoComplete="off"
                style={{ opacity: 0, height: 0 }}
                required
              />
            )}
          </div>
          {/* <div className="form-item">
            <label htmlFor="datum-dogadaja">Datum</label>
            <DatePicker
              name="datum-dogadaja"
              selected={date}
              onChange={date => setDate(date)}
              dateFormat="dd.MM.yyyy."
            />
            <RequiredPopup disabled={date} />
          </div> */}
          <div className="form-item">
            <label htmlFor="razlog">Razlog</label>
            <input
              type="text"
              id="razlog"
              name="razlog"
              placeholder="Razlog"
              size="30"
              required
              value={razlog}
              onChange={e => setRazlog(e.target.value)}
            />
          </div>
          <div className="form-item">
            <label htmlFor="napomena">Napomena</label>
            <input
              type="text"
              id="napomena"
              name="napomena"
              placeholder="Napomena"
              size="100"
              // required
              value={napomena}
              onChange={e => setNapomena(e.target.value)}
            />
          </div>
        </div>
        <div className="form-submit">
          <input type="submit" value="Prijava greške" />
        </div>
      </form>
    </article>
  );
}

export default PrijavaGreske2;

import React, { useState, useContext, useEffect } from "react";
import { getDistance } from "geolib";
import axios from "axios";
import TrazilicaKarta from "./TrazilicaKarta";
import TrazilicaObrazac from "./TrazilicaObrazac";
import TrazilicaRezultati from "./TrazilicaRezultati";

import { DataContext, CoordsContext } from "../App";

import { postajasFilter } from "./tmp/postajasFilter";
import { cjeniksFilter } from "./tmp/cjeniksFilter";
import { createAddOpenConsumer } from "./tmp/postajaConsumers";
import { compareFunction } from "./tmp/postajasCompare";
// import { postajasAdd } from "./tmp/postajasAdd";
import { getCurrentDay, getCurrentTime } from "./tmp/util";
// import { getDistanceAndTimeOfTwoLocations } from "./RoutingMachine";
// import Spinner from "./Spinner";

function TrazilicaGoriva(props) {
  const [filterArgs, setFilterArgs] = useState({
    tipGoriva: 1,
    radius: 5,
    vrstaGorivaArray: [],
    opcijaArray: [],
    danUTjednuArray: [],
    radnoVrijemeArray: [],
    obveznikArray: []
  });

  const [labelPostajaId, setLabelPostajaId] = useState();
  const handleOnMouseEnter = postajaId => {
    setLabelPostajaId(postajaId);
  };
  const handleOnMouseLeave = () => {
    setLabelPostajaId(null);
  };

  const widthToZoom = width => {
    width /= 2;
    const earthWidthInMeters = 12742;
    return Math.log(earthWidthInMeters / width) / Math.log(2);
  };

  // console.log(filterArgs.opcijaArray);

  const handleChange = event => {
    const newFilterArgs = { ...filterArgs };
    const target = event.target;
    const name = target.name;
    const value = target.value;
    newFilterArgs[name] = isNaN(+value) ? value : +value;
    setFilterArgs(newFilterArgs);
  };

  const { postajas } = useContext(DataContext);
  const { coords } = useContext(CoordsContext);

  useEffect(() => {
    if (!coords) {
      return;
    }

    // window.location = "#trazilica-otvorene-jump";
    window.location = "#main-nav-open";
  }, []);

  postajas.forEach((postaja, i) => {
    postaja.udaljenost = Math.round(
      getDistance(coords, {
        latitude: postaja.long,
        longitude: postaja.lat
      }) / 1000
    );
  });

  const filteredPostajas = postajasFilter(postajas, filterArgs);
  filteredPostajas.forEach(postaja => {
    const filteredCjeniks = cjeniksFilter(postaja.cjeniks, filterArgs);

    filteredCjeniks.sort(function(a, b) {
      return a.cijena - b.cijena;
    });

    if (filteredCjeniks.length === 0) {
      postaja.cijeneGoriva = [];
    }

    postaja.cijeneGoriva = filteredCjeniks.slice(
      0,
      Math.min(2, filteredCjeniks.length)
    );
  });

  const currentDay = getCurrentDay();
  const currentTime = getCurrentTime();

  const addOpenConsumer = createAddOpenConsumer(currentDay, currentTime);
  filteredPostajas.forEach(postaja => addOpenConsumer(postaja));

  filteredPostajas.sort(compareFunction);

  // console.log(filteredPostajas.length);

  return (
    <article className="trazilica-goriva">
      <TrazilicaKarta
        center={[coords.latitude, coords.longitude]}
        zoom={widthToZoom(filterArgs.radius)}
        postajas={filteredPostajas}
        labelPostajaId={labelPostajaId}
      />
      <TrazilicaObrazac
        filterArgs={filterArgs}
        setFilterArgs={setFilterArgs}
        onChange={handleChange}
        postajas={filteredPostajas}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <TrazilicaRezultati
        postajas={filteredPostajas}
        // coords={filterArgs.pozicija}
        coords={coords}
      />
    </article>
  );
}

export default TrazilicaGoriva;

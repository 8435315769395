import React from "react";

function Spinner() {
  return (
    // <main id="main-content">
    <div className="spinner" role="status">
      <img src="../loader.gif" alt="Učitavanje" />
    </div>
    // </main>
  );
}

export default Spinner;

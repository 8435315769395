import React, { useContext } from "react";
import L from "leaflet";
import { Map, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
//import MarkerClusterGroup from "./MarkerClusterGroup";
import PostajaNaKarti from "./PostajaNaKarti";
import { CoordsContext } from "../App";
import { getPostajaIcon } from "./tmp/icons";
import LocateControl from "../widgets/LocateControl";

function TrazilicaKarta(props) {
  const { coords, setCoords } = useContext(CoordsContext);
  let { center, zoom } = props;
  const { postajas, labelPostajaId } = props;

  if (!center) {
    center = [45.1, 15.2];
    zoom = 7;
  }

  if (window.innerWidth > 880) {
    switch (Math.round(zoom)) {
      case 12:
        center[1] -= 0.1;
        break;
      case 11:
        center[1] -= 0.2;
        break;
      case 10:
        center[1] -= 0.4;
        break;
      case 9:
        center[1] -= 0.8;
        break;
    }
  } else {
    center[0] += 0.015;
  }

  // var openIcon = L.divIcon({
  //   className: "my-div-icon",
  //   html: "<div class='outer-circle-green '></div>"
  // });

  // var closedIcon = L.divIcon({
  //   className: "my-div-icon",
  //   html: "<div class='outer-circle-red'></div>"
  // });

  // var openLabelIcon = L.divIcon({
  //   className: "my-div-icon",
  //   html: "<div class='outer-circle-green hover-border'></div>"
  // });

  // var closedLabelIcon = L.divIcon({
  //   className: "my-div-icon",
  //   html: "<div class='outer-circle-red hover-border'></div>"
  // });
  // const tmpFunc = postaja => {
  //   const icon =
  //     postaja.id === labelPostajaId
  //       ? postaja.open
  //         ? openLabelIcon
  //         : closedLabelIcon
  //       : postaja.open
  //       ? openIcon
  //       : closedIcon;
  //   return icon;
  // };

  // const hoveredPostaja = null;
  const hoveredPostaja = postajas.find(p => p.id === labelPostajaId);
  let hoveredPostajaMarker = null;
  if (hoveredPostaja) {
    hoveredPostajaMarker = (
      <Marker
        key={hoveredPostaja.id}
        position={[hoveredPostaja.long, hoveredPostaja.lat]}
        icon={getPostajaIcon(hoveredPostaja, true)}
        zIndexOffset={999}
      >
        <Popup>
          <PostajaNaKarti postaja={hoveredPostaja} showDetails={true} />
        </Popup>
      </Marker>
    );
  }

  const locateOptions = {
    cacheLocation: false,
    setView: false,
    markerStyle: { color: "rgba(44, 49, 60, 0.8)" },
    showPopup: false,
    position: "bottomright",
    strings: {
      title: "Locirajte se"
    },
    keepCurrentZoomLevel: true,
    drawCircle: false,
    onActivate: () => { } // callback before engine starts retrieving locations
  };

  return (
    <Map
      className="trazilica-karta"
      center={center}
      zoom={zoom}
      zoomControl={false}
      scrollWheelZoom={false}
      maxZoom={18}
    >
      <TileLayer
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        url={process.env.REACT_APP_TILE_URL}
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {/* <TileLayer
        url="https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}"
        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        maxZoom="18"
        id="mapbox.streets"
        accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      /> */}
      <LocateControl
        options={locateOptions}
        startDirectly
        setCoords={setCoords}
      />
      {coords && (
        <Marker position={[coords.latitude, coords.longitude]}>
          <Popup>Vi ste ovdje.</Popup>
        </Marker>
      )}
      <ZoomControl position="bottomright" />
      {/* <MarkerClusterGroup> */}
      {postajas
        .filter(p => p.id !== labelPostajaId)
        .map(postaja => (
          <Marker
            key={postaja.id}
            position={[postaja.long, postaja.lat]}
            icon={getPostajaIcon(postaja, false)}
          >
            <Popup>
              <PostajaNaKarti postaja={postaja} showDetails={true} />
            </Popup>
          </Marker>
        ))}
      {/* </MarkerClusterGroup> */}
      {hoveredPostajaMarker}
    </Map>
  );
}

export default TrazilicaKarta;

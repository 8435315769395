import React from "react";
import { Map, TileLayer, Marker } from "react-leaflet";
import { getDistance } from "geolib";
import Routing from "./RoutingMachine";

class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: props.postaja.long,
      lng: props.postaja.lat,
      coords: props.coords,
      zoom: 13,
      isMapInit: false
    };
  }

  saveMap = map => {
    this.map = map;
    this.setState({
      isMapInit: true
    });
  };

  render() {
    const { lat, lng, coords, zoom } = this.state;
    const position = [lat, lng];

    const widthToZoom = width => {
      width /= 2;
      const earthWidthInMeters = 12742;
      return Math.log(earthWidthInMeters / width) / Math.log(2);
    };

    return (
      <Map
        id="leaflet-map"
        className="postaja-karta"
        center={[coords.latitude, coords.longitude]}
        zoom={zoom}
        zoom={widthToZoom(
          getDistance(coords, {
            latitude: lat,
            longitude: lng
          }) / 1000
        )}
        ref={this.saveMap}
        scrollWheelZoom={false}
      >
        <TileLayer
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url={process.env.REACT_APP_TILE_URL}
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {/* <TileLayer
          // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}"
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
          maxZoom="18"
          id="mapbox.streets"
          accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        /> */}
        {/* {!this.props.coords && <Marker position={this.props.coords}></Marker>} */}
        {this.state.isMapInit && this.props.coords && (
          <Routing
            map={this.map}
            coords={this.props.coords}
            postaja={this.props.postaja}
          />
        )}
      </Map>
    );
  }
}

export default MapComponent;

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { DataContext } from "../App";
import Grafikon from "./Grafikon";
import ErrorComponent from "./ErrorComponent";

function NaslovnicaGrafikon(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [labels, setLabels] = useState();
  const [series, setSeries] = useState();
  const [legendNames, setLegendNames] = useState();
  const { tip_gorivas, tip_gorivas_obj } = useContext(DataContext);
  useEffect(() => {
    let didCancel = false;

    axios
      .get(`${process.env.REACT_APP_BACKEND_API_URL}/trend-cijena`)
      .then(function(response) {
        if (didCancel) {
          return;
        }

        const tip_goriva_ids = tip_gorivas.map(tip_goriva => tip_goriva.id);
        const data = response.data.filter(x =>
          tip_goriva_ids.includes(x.tip_goriva_id)
        );
        const tgId2Arr = {};
        tip_goriva_ids.forEach(tgId => {
          tgId2Arr[tgId] = [];
        });
        const dates = [];
        let lastDate = null;
        data.forEach(x => {
          const date = x.dat_poc;
          if (!lastDate || date !== lastDate) {
            tip_goriva_ids.forEach(tgId => {
              tgId2Arr[tgId].push(null);
            });
            dates.push(date);
            lastDate = date;
          }
          const arr = tgId2Arr[x.tip_goriva_id];
          arr.pop();
          arr.push(x.avg_cijena);
        });
        function formatDateStr(dateStr) {
          const [y, m, d] = dateStr.split("-");
          return `${d}.${m}.${y}.`;
        }
        const series = [];
        const legendNames = [];
        for (let [tgId, arr] of Object.entries(tgId2Arr)) {
          series.push(
            arr.map((x, i) => ({ meta: formatDateStr(dates[i]), value: x }))
          );
          legendNames.push(tip_gorivas_obj[tgId].tip_goriva);
        }

        setLabels(dates.map(x => formatDateStr(x)));
        setSeries(series);
        setLegendNames(legendNames);
        setLoading(false);
      })
      .catch(function(error) {
        if (didCancel) {
          return;
        }

        console.log(error);
        setError(true);
        setLoading(false);
      });

    return () => {
      didCancel = true;
    };
  }, []);

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <ErrorComponent title="Greška prilikom dohvaćanja podataka za Grafikon cijena." />
    );
  }

  return (
    <Grafikon
      labels={labels}
      series={series}
      legendNames={legendNames}
      title={"Grafikon prosječnih cijena"}
    />
  );
}

export default NaslovnicaGrafikon;

import React, { Component } from "react";
import { withLeaflet } from "react-leaflet";
import Locate from "leaflet.locatecontrol";

class LocateControl extends Component {
  componentDidMount() {
    const { options, startDirectly, setCoords } = this.props;
    const { map } = this.props.leaflet;

    const lc = new Locate(options);
    lc.addTo(map);

    map.on("locationfound", function(e) {
      lc.stop();
      setCoords({
        latitude: e.latlng.lat,
        longitude: e.latlng.lng + (1 + Math.random()) * 1e-6
      });
    });

    lc.stop();
    // if (startDirectly) {
    //   // request location update and set location
    //   lc.start();
    // }
  }

  render() {
    return null;
  }
}

export default withLeaflet(LocateControl);

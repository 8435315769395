import React from "react";
import { Map, Marker, Popup, TileLayer, ZoomControl } from "react-leaflet";
import MarkerClusterGroup from "./MarkerClusterGroup";
import PostajaNaKarti from "./PostajaNaKarti";
import { getPostajaIcon } from "./tmp/icons";

function ObvezniciKarta(props) {
  const { postaje, mapRef } = props;

  const center = [44.7, 16.3];
  const zoom = 7;

  return (
    <Map
      className="obveznici-karta"
      ref={mapRef}
      center={center}
      zoom={zoom}
      zoomControl={false}
      scrollWheelZoom={false}
      maxZoom={18}
    >
      <TileLayer
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        url={process.env.REACT_APP_TILE_URL}
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {/* <TileLayer
        url="https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}"
        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'
        maxZoom="18"
        id="mapbox.streets"
        accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      /> */}
      <ZoomControl position="bottomright" />
      <MarkerClusterGroup>
        {postaje.map(postaja => (
          <Marker
            key={postaja.id}
            position={[postaja.long, postaja.lat]}
            icon={getPostajaIcon(postaja, false)}
          >
            <Popup>
              <PostajaNaKarti postaja={postaja} showDetails={true} />
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </Map>
  );
}

export default ObvezniciKarta;

import React, { useEffect, useState, useRef, useContext } from "react";
import Select from "react-select";
import { getDistance } from "geolib";
import ObvezniciKarta from "./ObvezniciKarta";
import ObvezniciRezultati from "./ObvezniciRezultati";

import { DataContext, CoordsContext } from "../App";
import { getCurrentDay, getCurrentTime } from "./tmp/util";
import { createAddOpenConsumer } from "./tmp/postajaConsumers";
import { compareFunction2 } from "./tmp/postajasCompare";

function Obveznici(props) {
  const mapRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    mapRef.current.leafletElement.invalidateSize();
  }, []);

  const [izabraniObveznici, setIzabraniObveznici] = useState([]);

  const handleChange = e => {
    if (!e) {
      setIzabraniObveznici([]);
      return;
    }

    setIzabraniObveznici(e.map(x => x.value));
  };

  const izabraniObvezniciPredicate = postaja => {
    if (izabraniObveznici.length === 0) {
      return false;
    }
    return izabraniObveznici.includes(postaja.obveznik.id);
  };

  const { postajas, obvezniks } = useContext(DataContext);

  const filtriranePostaje = postajas.filter(izabraniObvezniciPredicate);

  filtriranePostaje.forEach(postaja => {
    const cijeneVrstaGorivaPoTipu = {};
    postaja.cjeniks.forEach(cijenaGoriva => {
      const tipGorivaId = cijenaGoriva.gorivo.vrsta_goriva.tip_goriva_id;
      let cijeneVrstaGoriva = cijeneVrstaGorivaPoTipu[tipGorivaId];
      if (cijeneVrstaGoriva === undefined) {
        cijeneVrstaGoriva = [];
        cijeneVrstaGorivaPoTipu[tipGorivaId] = cijeneVrstaGoriva;
      }

      cijeneVrstaGoriva.push(cijenaGoriva);
    });

    for (let key in cijeneVrstaGorivaPoTipu) {
      cijeneVrstaGorivaPoTipu[key].sort(function (a, b) {
        return a.cijena - b.cijena;
      });
    }

    postaja.cijeneGoriva = [];
    Object.values(cijeneVrstaGorivaPoTipu).forEach(
      cijeneVrstaGorivaOdredenogTipa => {
        postaja.cijeneGoriva.push(cijeneVrstaGorivaOdredenogTipa[0]);
      }
    );
  });

  const currentDay = getCurrentDay();
  const currentTime = getCurrentTime();

  const addOpenConsumer = createAddOpenConsumer(currentDay, currentTime);
  filtriranePostaje.forEach(postaja => addOpenConsumer(postaja));

  const { coords } = useContext(CoordsContext);
  filtriranePostaje.forEach((postaja, i) => {
    // console.log(postaja.long, postaja.lat);
    postaja.udaljenost = Math.round(
      getDistance(coords, {
        latitude: postaja.long,
        longitude: postaja.lat
      }) / 1000
    );
  });

  filtriranePostaje.sort(compareFunction2);

  return (
    <main id="main-content" className="obveznici-stranica">
      <h1>Obveznici</h1>
      <article>
        <section className="obveznici-trazilica">
          <form id="obveznici-trazilica" action="">
            <div>
              <label className="traziob-label" htmlFor="traziobveznici">
                Svi obveznici
              </label>
              <Select
                placeholder={"Pretražite obveznike"}
                options={obvezniks.map(obveznik => ({
                  value: obveznik.id,
                  label: obveznik.naziv
                }))}
                isMulti={true}
                onChange={handleChange}
              />
              {/* <article className="postaja">
                <img src="../tifon_logo.png"></img>
                <h3>Tifon</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  nec ligula sit amet dolor suscipit iaculis. Duis non auctor
                  lectus, ac gravida elit. Sed lobortis, erat eget lobortis
                  luctus, metus nisl malesuada mauris, sed pretium odio ex sed
                  diam. Quisque rhoncus semper molestie. Donec vitae orci sit
                  amet nunc ultrices tempus. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit. Vestibulum sit amet urna id est
                  condimentum pharetra id a mauris.
                </p>
              </article> */}
            </div>
          </form>
        </section>
        <ObvezniciKarta postaje={filtriranePostaje} mapRef={mapRef} />
        <ObvezniciRezultati
          postaje={filtriranePostaje}
          atLeastOneElected={izabraniObveznici.length > 0}
        />
      </article>
    </main>
  );
}

export default Obveznici;
